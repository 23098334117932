import React, { ReactNode } from 'react';
import { Button } from '../../ui/button';
import { FlexGrid } from '../../ui/flex-grid';
import { Typography } from '../../ui/typography';
import { Logos } from '../logos';

const shouldDisplay = (val?: boolean) => (!val ? 'hidden' : 'visible');

export type QuestionaryScreenSMProps = {
  onNext: () => void;
  onBack: () => void;
  hasNext?: boolean;
  hasBack?: boolean;
};

export type QuestionaryScreenProps = {
  title: string;
  children: ReactNode;
  nextOverrideText?: string;
  backOverrideText?: string;
} & QuestionaryScreenSMProps;

export const QuestionaryScreen = ({
  title,
  children,
  onBack,
  onNext,
  hasBack,
  hasNext,
  nextOverrideText,
  backOverrideText,
}: QuestionaryScreenProps) => {
  return (
    <FlexGrid
      style={{ boxSizing: 'border-box' }}
      padding={6}
      fullHeight
      container
      direction='column'
      justify='space-between'
    >
      <FlexGrid item container direction='row' justify='space-between'>
        <FlexGrid item>
          <Typography
            style={{
              maxWidth: '850px',
            }}
            align='justify'
            variant='smallTitle'
          >
            {title}
          </Typography>
        </FlexGrid>
        <FlexGrid item>
          <Logos />
        </FlexGrid>
      </FlexGrid>
      <FlexGrid item container justify='center'>
        {children}
      </FlexGrid>
      <FlexGrid
        style={{
          margin: '0px -40px',
        }}
        item
        container
        direction='row'
        justify='space-between'
      >
        <FlexGrid item>
          <Button
            style={{
              padding: '1rem 2rem',
              textAlign: 'right',
              visibility: shouldDisplay(hasBack),
            }}
            size='large'
            onClick={onBack}
            color='secondary'
          >
            {backOverrideText || 'Retroceder'}
          </Button>
        </FlexGrid>
        <FlexGrid item>
          <Button
            style={{
              padding: '1rem 2rem',
              textAlign: 'left',
              visibility: shouldDisplay(hasNext),
            }}
            size='large'
            onClick={onNext}
            color='primary'
          >
            {nextOverrideText || 'Continuar'}
          </Button>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
