import styled from 'styled-components';

export const StyledTextFiled = styled.input`
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  box-shadow: none !important;
  border: none;
  padding: 12px;
  font-size: 18px;
  box-sizing: border-box;
`;
