import React, { CSSProperties, memo, useContext } from 'react';
import { theme } from '../../../theme';
import { typographyContext } from './typography-context';

export type TypographyProps = {
  color?: keyof typeof theme.colors;
  variant?: keyof typeof theme.typography;
  children: any;
  className?: string;
  align?: 'center' | 'right' | 'left' | 'justify';
  style?: CSSProperties;
  fixHeight?: string;
  weight?: 'bold' | 'normal' | 'light' | number | string;
  gutterBottom?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>;

export const Typography = memo(
  ({
    children,
    align,
    className,
    color,
    style,
    variant,
    fixHeight,
    weight,
    gutterBottom,
    ...props
  }: TypographyProps) => {
    const typographyComponents = useContext(typographyContext);
    const TypographyComponent =
      typographyComponents.components[variant || 'paragraph'];
    return (
      <TypographyComponent
        align={align}
        className={className}
        color={color}
        style={style}
        fixHeight={fixHeight}
        weight={weight}
        gutterBottom={gutterBottom}
        {...props}
      >
        {children}
      </TypographyComponent>
    );
  }
);
