import './styles.css';
import { BrowserRouter } from 'react-router-dom';
import { MainRouter } from './router';
import { ThemeProvider } from './providers/theme-provider';
import { CursoProvider } from './providers/access-provider';
export default function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <CursoProvider>
          <MainRouter />
        </CursoProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
