import styled from 'styled-components';

export const AnswerItem = styled.div<{ isSlected?: boolean; color: string }>`
  width: 175px;
  height: 125px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 900;
  &::hover {
    background: rgba(0, 0, 0, 0.1);
  }
  ${({ isSlected, color }) =>
    isSlected
      ? `
    background: ${color};
    color:white;
    border: 1px solid ${color};
  `
      : `
    background: white;
    color:${color};
    border: 1px solid ${color};    
      `}
`;
