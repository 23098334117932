import { ReactNode, useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import { theme as baseTheme } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { TypographyProvider } from '../../components/ui/typography/typography-context';

function useTheme() {
  const [theme, setTheme] = useState(baseTheme);
  return { theme, setTheme };
}

const { Provider, useProvider } = makeProvider(useTheme);

const StyledThemeProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { theme } = useProvider();

  return (
    <StyledThemeProvider theme={theme}>
      <TypographyProvider theme={theme}>{children}</TypographyProvider>
    </StyledThemeProvider>
  );
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => (
  <Provider>
    <StyledThemeProviderWrapper>{children}</StyledThemeProviderWrapper>
  </Provider>
);

export const useThemeProvider = useProvider;
