import { useState, useEffect, useCallback } from 'react';

function useFetch<T>(
  apiFn: (...args: any[]) => Promise<T>,
  params: any[],
  options: { disabled?: boolean } = {}
) {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const load = useCallback(() => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      (apiFn as any)(...params)
        .then((data: any) => {
          resolve(data);
          setData(data);

          // setError(data);

          setLoading(false);
        })
        .catch((e: any) => {
          reject(e);
          setError(e);
          setLoading(false);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...params]);

  useEffect(() => {
    if (!options.disabled) {
      load();
    }
  }, [load, options.disabled]);

  return { data, loading, error, load };
}

export default useFetch;
