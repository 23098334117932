import React from 'react';
import { StyledTextFiled } from './styled';

export type TextFieldSMProps = {
  value?: string;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  style?: React.CSSProperties
};

export type TextFieldProps = {
  placeholder?: string;
} & TextFieldSMProps;

export const TextField = ({ onInput, value, placeholder, style }: TextFieldProps) => {
  return (
    <StyledTextFiled
      style={style}
      onInput={onInput}
      value={value}
      placeholder={placeholder}
    />
  );
};
