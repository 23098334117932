import { Card } from '../../ui/card';
import { FlexGrid } from '../../ui/flex-grid';
import imssLogo from '../../../assets/imss-logo.svg';
import { Typography } from '../../ui/typography';
import { TextField, TextFieldSMProps } from '../../ui/text-field';
import { Button } from '../../ui/button';

export type AccessFormProps = {
  onAccess: () => void;
  accessTextField: TextFieldSMProps;
  accessCompanyNameField: TextFieldSMProps;
  error?: any
};

export const AccessForm = ({ onAccess, accessTextField, error }: AccessFormProps) => {
  return (
    <Card
      background='secondary'
      style={{
        width: '100%',
        maxWidth: '435px',
        height: '100vh',
        maxHeight: '460px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FlexGrid
        fullHeight
        direction='column'
        justify='center'
        alignItems='center'
        container
        spacing={4}
      >
        <FlexGrid item>
          <img src={imssLogo} width='120px' alt='Ims Logo' />
        </FlexGrid>
        <FlexGrid style={{ width: '300px' }} item>
          <Typography
            style={{ paddingBottom: '12px' }}
            gutterBottom
            variant='h2'
            align='center'
          >
            Ingrese codigo
          </Typography>
          <TextField {...accessTextField} placeholder='Codigo' />
        </FlexGrid>
        <FlexGrid style={{ width: '300px' }} item>
          <Button fullWidth onClick={onAccess} color='primary'>
            Acceder
          </Button>
        </FlexGrid>

        <FlexGrid style={{ width: '300px' }} item>
          <Typography

            gutterBottom
            variant='smallTitle'
            align='center'
            color='danger'
          >
            {error && "Datos incorrectos, por favor ingrese un codigo valido."}
          </Typography>
        </FlexGrid>
      </FlexGrid>
    </Card>
  );
};
