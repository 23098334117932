import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AccessFormProps } from '..';
import { useCurso } from '../../../../providers/access-provider';
import { useTextField } from '../../../ui/text-field/hooks/use-textfield';
export const useAccessForm = (): AccessFormProps => {
  const { curso, setPacienteId, error: cursoError } = useCurso();
  const [error, setError] = useState(false)
  const accessTextField = useTextField();
  const accessCompanyNameField = useTextField();
  const history = useHistory();

  const access = useCallback(() => {
    if (accessTextField.value && accessTextField.value.trim() !== "")
      setPacienteId(Number(accessTextField.value) - 10000 + '');
    else
      setError(true)
    //  setCursoId(Number(accessTextField.value) - 10000 + '');
  }, [accessTextField, setPacienteId]);
  useEffect(() => {
    if (curso) {
      history.push('/questionary', { companyName: accessCompanyNameField.value });
    }
  }, [curso, history, accessCompanyNameField.value]);
  return {
    accessTextField,
    onAccess: access,
    accessCompanyNameField,
    error: error || cursoError
  };
};
