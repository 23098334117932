import React, {
  ReactNode,
  createContext,
  CSSProperties,
  memo,
  forwardRef,
} from 'react';
import { GridContainer, GridItem } from './styled';

export const gridContext = createContext({});

const componentTypes = {
  item: GridItem,
  container: GridContainer,
};

export type FlexGridProps = {
  container?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  padding?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  item?: boolean;
  children: ReactNode;
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  className?: string;
  style?: CSSProperties;
  basis?: 'content' | 'inherit' | 'initial' | 'unset' | string;
  grow?: string | number;
  shrink?: string | number;
  flex?: string | number;
  fullHeight?: boolean;
  fullWidth?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const FlexGrid = memo(
  forwardRef<HTMLDivElement, FlexGridProps>((props: FlexGridProps, ref) => {
    const { container, children } = props;
    const GridSelected = componentTypes[container ? 'container' : 'item'];

    return (
      <GridSelected {...props} ref={ref}>
        {children}
      </GridSelected>
    );
  })
);
