import React from 'react';
import { useParams } from 'react-router';
import { FlexGrid } from '../../components/ui/flex-grid';
import { Typography } from '../../components/ui/typography';
export const Code = () => {
  const params: any = useParams();
  return (
    <FlexGrid
      padding={6}
      style={{
        height: '100vh',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
      }}
      fullHeight
      container
      justify='center'
      alignItems='center'
      direction='column'
    >
      <Typography style={{ color: 'gray' }} variant='h2'>
        Codigo
      </Typography>
      <Typography variant='h1'>{Number(params.id) + 10000}</Typography>
    </FlexGrid>
  );
};
