import React from 'react';

export const VideoPlayer = ({ id }: { id?: string }) => {
  console.log(id);
  return (
    <video
      key={id}
      autoPlay
      style={{
        maxWidth: '900px',
        width: '80%',
        maxHeight: 'calc(100vh - 200px)',
      }}
      controls
    >
      <source
        key={id}
        src={'https://storage.googleapis.com/videos-pacientes/' + id}
        type='video/mp4'
      />
      Your browser does not support HTML video.
    </video>
  );
};
