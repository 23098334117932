import { QuestionaryScreen } from '../../components/domain/questionary-screen';
import { FlexGrid } from '../../components/ui/flex-grid';
import { useQuestionary } from './use-questionary';

export const Questionary = (state: any) => {
  console.log(state.location.state.companyName)
  console.log(state)
  const {
    currentSection,
    hasBackNext: { hasBack, hasNext },
    onBack,
    onNext,
    question,
    loadingCuestionario,
  } = useQuestionary(state?.location?.state?.companyName);
  console.log(currentSection);
  return loadingCuestionario ? null : (
    <FlexGrid
      style={{
        height: '100vh',
      }}
    >
      <QuestionaryScreen
        onBack={onBack}
        onNext={onNext}
        title={currentSection.title}
        hasBack={hasBack}
        hasNext={hasNext}
        nextOverrideText={currentSection.nextOverrideText}
      >
        <currentSection.Component
          {...question}
          id={currentSection.id}
          question={currentSection.question || ''}
        />
      </QuestionaryScreen>
    </FlexGrid>
  );
};
