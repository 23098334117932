import styled from 'styled-components';
import { ThemeColorType } from '../../../theme';

export const StyledButton = styled.button<{
  color: ThemeColorType;
  size: 'normal' | 'large';
}>`
  ${({ size }) =>
    size === 'large' &&
    `
    width: 300px;
  `}
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: ${({ color }) => (color === 'primary' ? '#235B4E' : '#F3EDE1')};
  color: ${({ color }) => (color === 'primary' ? 'white' : 'gray')};
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
`;
