import styled from 'styled-components';
import { theme } from '../../../theme';

const alignFn = ({ align }: any) => align || 'left';
const fixHeight = ({ fixHeight }: any) =>
  fixHeight
    ? `
        display:flex;
        aligin-items:center;
        ${fixHeight ? `height:${fixHeight};` : ''}
    `
    : '';

export const createStyledComponent = (
  type: keyof typeof theme.typography,
  styles: any
) => {
  const htmlElement =
    type === 'paragraph'
      ? 'p'
      : type === 'smallTitle'
      ? 'h3'
      : type === 'small'
      ? 'span'
      : type;

  return styled[htmlElement]`
    line-height: normal;
    padding: 0;
    margin: 0;
    display: block;

    ${(({ gutterBottom }: any) =>
      gutterBottom ? `margin-bottom:.35em;` : '') as any};
    color: ${({ theme, color }) => theme.colors[color || 'charcoal']};
    text-align: ${alignFn as any};
    ${fixHeight as any}
    ${styles}
   ${(({ weight }: any) => (weight ? `font-weight:${weight};` : '')) as any};
  `;
};
