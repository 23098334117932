import { useCallback, useState } from 'react';
import { TextFieldSMProps } from '..';

export const useTextField = (): TextFieldSMProps => {
  const [value, setValue] = useState('');
  const onInput = useCallback((ev) => {
    setValue(ev.target.value);
  }, []);
  return {
    onInput,
    value,
  };
};
