import DirectusSDK from '@directus/sdk-js';

export const directus = new DirectusSDK('https://api.passimsssinaloa.com/');

export function getCuestionarios(cuestionarioId?: string, videosIds?: any[]) {
  return async () => {
    const cuestionario = await directus
      .items('cuestionarios')
      .read(cuestionarioId || '');
    console.log('Preguntas');
    console.log(cuestionario?.data?.preguntas);

    const cuestionarioPreguntas = await directus
      .items('cuestionarios_pregunta_2')
      .read(cuestionario?.data?.preguntas);

    const preguntas = await directus
      .items('pregunta')
      .read(
        cuestionarioPreguntas?.data?.map(({ pregunta_id }: any) => pregunta_id)
      );
    const cursosVideos = await directus
      .items('cursos_directus_files_2')
      .read(videosIds || []);
    const videos = await directus.files.read(
      cursosVideos.data?.map(
        ({ directus_files_id }: any) => directus_files_id
      ) || []
    );
    console.log(videos);
    return {
      cuestionario,
      preguntas,
      videos,
    };
  };
}
