import { useCallback } from 'react';
import { useThemeProvider } from '../../../providers/theme-provider';
import { FlexGrid } from '../../ui/flex-grid';
import { Typography } from '../../ui/typography';
import { AnswerItem } from './styled';

export type Answer = 'yes' | 'no' | 'idk';

export type QuestionSMProps = {
  reset: () => void;
  onAnswerSelect: (answer: Answer) => void;
  answerSelected?: Answer;
};

export type QuestionProps = {
  question: string;
} & QuestionSMProps;

export const Question = ({
  question,
  onAnswerSelect,
  answerSelected,
}: QuestionProps) => {
  const {
    theme: { colors },
  } = useThemeProvider();
  const selectYes = useCallback(() => {
    onAnswerSelect('yes');
  }, [onAnswerSelect]);
  const selectNo = useCallback(() => {
    onAnswerSelect('no');
  }, [onAnswerSelect]);
  const selectIdk = useCallback(() => {
    onAnswerSelect('idk');
  }, [onAnswerSelect]);
  return (
    <FlexGrid container direction='column' spacing={7}>
      <FlexGrid item>
        <Typography
          style={{ fontSize: '28px' }}
          variant='paragraph'
          color='primary'
          align='center'
          gutterBottom
        >
          {question}
        </Typography>
      </FlexGrid>
      <FlexGrid
        item
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={7}
      >
        <FlexGrid item>
          <AnswerItem
            onClick={selectYes}
            color={colors.primary}
            isSlected={answerSelected === 'yes'}
          >
            Correcto
          </AnswerItem>
        </FlexGrid>
        <FlexGrid item>
          <AnswerItem
            onClick={selectNo}
            color={colors.primary}
            isSlected={answerSelected === 'no'}
          >
            Incorrecto
          </AnswerItem>
        </FlexGrid>
        <FlexGrid item>
          <AnswerItem
            onClick={selectIdk}
            color={colors.primary}
            isSlected={answerSelected === 'idk'}
          >
            No se
          </AnswerItem>
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
