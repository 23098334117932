import { AccessForm } from '../../components/domain/access-form';
import { useAccessForm } from '../../components/domain/access-form/hooks/use-access-form';
import { Logos } from '../../components/domain/logos';
import { FlexGrid } from '../../components/ui/flex-grid';
import { Typography } from '../../components/ui/typography';

export const Access = () => {
  const accessForm = useAccessForm();
  return (
    <FlexGrid
      padding={6}
      style={{
        height: '100vh',
        minHeight: 'fit-content',
        boxSizing: 'border-box',
      }}
      fullHeight
      container
      justify='center'
      alignItems='center'
      direction='column'
    >
      <FlexGrid
        style={{ marginBottom: 'auto' }}
        fullWidth
        item
        container
        justify='space-between'
      >
        <FlexGrid>
          <Typography
            style={{
              maxWidth: '850px',
            }}
            variant='smallTitle'
            align='justify'
          >
            ORGANO DE OPERACIÓN ADMINISTRATIVA DESCONCENTRADA EN SINALOA <br />
            JEFATURA DE SERVICIOS DE SALUD EN EL TRABAJO, PRESTACIONES
            ECONOMICAS Y SOCIALES. <br /> DEPARTAMENTO DE PRESTACIONES SOCIALES
          </Typography>
        </FlexGrid>
        <FlexGrid>
          <Logos />
        </FlexGrid>
      </FlexGrid>
      <FlexGrid container justify='center' alignItems='center' item flex='1'>
        <FlexGrid item>
          <AccessForm {...accessForm} />
        </FlexGrid>
      </FlexGrid>
    </FlexGrid>
  );
};
