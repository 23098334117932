import { useState } from 'react';
import { makeProvider } from 'react-provider-maker';
import useFetch from '../../hooks/use-fetch';
import { directus } from '../../api';

export const { Provider: CursoProvider, useProvider: useCurso } = makeProvider(
  () => {
    const [pacienteId, setPacienteId] = useState<string | undefined>();
    const { data: asignacion, error } = useFetch(
      () => directus.items('pacientes').read(pacienteId || ''),
      [pacienteId],
      { disabled: !pacienteId }
    );
    console.log("ERROR")
    console.log(error)
    const {
      data: curso,
      loading: loadingCurso,
      error: cursoError,
    } = useFetch(() => directus.items('cursos').read('4' || ''), [asignacion], {
      disabled: !asignacion,
    });

    return {
      curso,
      pacienteId,
      loadingCurso,
      cursoError,
      setPacienteId,
      error
    };
  }
);
