import { FlexGrid } from '../../ui/flex-grid';
import mexico2021 from '../../../assets/2023.png';
import imslogo from '../../../assets/elssa-logo.jpg';
export const Logos = () => {
  return (
    <FlexGrid container item spacing={3}>
      <img style={{ marginTop: "20px" }} width={100} height={"120"} src={mexico2021} alt='mexico2021' />
      <img width='300' src={imslogo} alt='mexico20212' />
    </FlexGrid>
  );
};
