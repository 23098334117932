import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { useThemeProvider } from '../../../providers/theme-provider';
import { ThemeColorType } from '../../../theme';
import { CardContainer } from './styled';

export type CardProps = {
  background?: ThemeColorType;
  children: ReactNode;
  style?: CSSProperties;
};

export const Card = ({ children, background, style }: CardProps) => {
  const {
    theme: { colors },
  } = useThemeProvider();
  return (
    <CardContainer
      style={{ ...style }}
      background={background && colors[background]}
    >
      {children}
    </CardContainer>
  );
};
