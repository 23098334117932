import { useCallback, useState } from 'react';
import { Answer, QuestionSMProps } from '..';

export const useQuestion = (): QuestionSMProps => {
  const [answer, setAnswer] = useState<Answer | undefined>();
  const selectAnswer = useCallback((answer: Answer) => {
    setAnswer(answer);
  }, []);
  const reset = useCallback(() => {
    setAnswer(undefined);
  }, []);
  return {
    reset,
    answerSelected: answer,
    onAnswerSelect: selectAnswer,
  };
};
