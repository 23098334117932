export type TypographyTypes = 'h1' | 'h2' | 'paragraph' | 'small';

export type ThemeColorType = keyof typeof theme.colors;

export type ITheme = typeof theme;

export const theme = {
  typography: {
    h1: {
      fontSize: '38px',
      fontWeight: 'bold',
    },
    h2: { fontSize: '24px', fontWeight: 'bold' },
    smallTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    paragraph: {
      fontSize: '14px',
      fontWeight: 400,
    },
    small: {
      fontSize: '12px',
    },
  },
  spaces: [0, 8, 12, 16, 24, 32, 40, 48],
  spacesPx: ['0px', '8px', '12px', '16px', '24px', '32px', '40px', '48px'],
  colors: {
    primary: '#235B4E',
    secondary: '#F3EDE1',
    danger: '#dc3545',
  },
};
