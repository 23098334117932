import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuestion } from '../../components/domain/question/hooks/use-question';
import { useCurso } from '../../providers/access-provider';
import { useHistory } from 'react-router';
import { getCuestionarios } from '../../api';
import useFetch from '../../hooks/use-fetch';
import { createSections } from './helper';
import { directus } from '../../api';
export const useQuestionary = (companyName: string) => {
  const { curso, pacienteId } = useCurso();
  const history = useHistory();
  const [respuestas, setRespuestas] = useState<any[]>([]);
  console.log(curso);
  const { data: questionaryData, loading: loadingCuestionario } = useFetch(
    getCuestionarios(curso?.data?.cuestionario, curso?.data?.videos),
    []
  );

  const sections = useMemo(
    () =>
      !loadingCuestionario &&
      createSections(
        questionaryData?.cuestionario,
        questionaryData?.preguntas,
        questionaryData?.videos,
        curso
      ),
    [questionaryData, curso, loadingCuestionario]
  );

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const currentSection = sections && sections[currentSectionIndex];

  console.log(questionaryData);

  const [hasBackNext, setHasBackNext] = useState<{
    hasNext?: boolean;
    hasBack?: boolean;
  }>({
    hasNext: false,
    hasBack: false,
  });
  useEffect(() => {
    if (!curso) {
      history.push('/access');
    }
  }, [curso, history]);
  useEffect(() => {
    setHasBackNext({
      hasBack: currentSection.hasBack,
      hasNext: currentSection.hasNext,
    });
  }, [currentSectionIndex, currentSection.hasBack, currentSection.hasNext]);

  const question = useQuestion();

  useEffect(() => {
    console.log('next effect');

    if (question.answerSelected && !hasBackNext.hasNext) {
      setHasBackNext({
        ...hasBackNext,
        hasNext: true,
      });
    }
  }, [question.answerSelected, hasBackNext]);

  const onNext = useCallback(async () => {
    if (currentSection.type === 'question') {
      setRespuestas((respuestas) => [
        ...respuestas,
        {
          id: currentSection.id,
          isSecondTime: currentSection.isSecondTime,
          question: currentSection.question,
          answer: question.answerSelected,
        },
      ]);
    }
    if (sections && !sections[currentSectionIndex + 1]) {
      debugger;
      await directus.items('asignaciones').create({
        paciente: pacienteId,
        respuestas,
        cuestionario: curso?.data?.cuestionario,
        curso: curso?.data?.id,
        nombre_empresa: companyName
      });
      window.location.reload();
    }
    question.reset();
    setCurrentSectionIndex(currentSectionIndex + 1);
  }, [
    currentSection.type,
    currentSection.id,
    currentSection.isSecondTime,
    currentSection.question,
    sections,
    currentSectionIndex,
    question,
    pacienteId,
    respuestas,
    curso?.data?.cuestionario,
    curso?.data?.id,
    companyName
  ]);

  const onBack = useCallback(() => {
    question.reset();
    setCurrentSectionIndex(currentSectionIndex - 1);
  }, [currentSectionIndex, question]);
  console.log(sections);
  return {
    currentSection,
    question,
    hasBackNext,
    onNext,
    onBack,
    loadingCuestionario,
  };
};
