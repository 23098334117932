import styled from 'styled-components';
import { FlexGridProps } from './index';

// export const GridContainer = styled.div<FlexGridProps>`
//   display: flex;
//   line-height: 100%;

//   flex-direction: ${({ direction }) => direction};
//   align-items: ${({ alignItems }) => alignItems};
//   justify-content: ${({ justify }) => justify};

//   ${({ item, padding, theme: { spaces } }) =>
//     item ? '' : `padding:${spaces[padding || 0]}px;`}

//   ${({ flex }) => (flex ? `flex:${flex};` : '')}
//   ${({ basis }) => (basis ? `flex-basis:${basis};` : '')}
//   ${({ grow }) => (grow ? `flex-grow:${grow};` : '')}
//   ${({ shrink }) => (shrink ? `flex-shrink:${shrink};` : '')}

//   gap:  ${({ spacing, theme: { spaces } }) => spaces[spacing || 0]}px
// `;

export const GridContainer = styled.div<FlexGridProps>`
  display: flex;
  line-height: 100%;
  margin: ${({ spacing, theme: { spaces } }) =>
    (spaces[spacing || 0] / 2) * -1}px;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justify }) => justify};

  ${({ item, padding, theme: { spaces } }) =>
    item ? '' : `padding:${spaces[padding || 0]}px;`}

  ${({ flex }) => (flex ? `flex:${flex};` : '')}
  ${({ basis }) => (basis ? `flex-basis:${basis};` : '')}
  ${({ grow }) => (grow ? `flex-grow:${grow};` : '')}
  ${({ shrink }) => (shrink ? `flex-shrink:${shrink};` : '')}

  ${({ fullHeight }) => (fullHeight ? `height:100%;` : '')}
  ${({ fullWidth }) => (fullWidth ? `width:100%;` : '')}
 /* box-sizing:content-box;*/
  & > * {
    padding: ${({ spacing, theme: { spaces } }) => spaces[spacing || 0] / 2}px;
  }
`;

export const GridItem = styled.div<FlexGridProps>`
  line-height: 100%;
  ${({ flex }) => (flex ? `flex:${flex};` : '')}
  ${({ basis }) => (basis ? `flex-basis:${basis};` : '')}
  ${({ grow }) => (grow ? `flex-grow:${grow};` : '')}
  ${({ shrink }) => (shrink ? `flex-shrink:${shrink};` : '')}
  ${({ fullHeight }) => (fullHeight ? `height:100%;` : '')}
  ${({ fullWidth }) => (fullWidth ? `width:100%;` : '')}
 /* box-sizing:content-box;*/
  & > * {
    flex: 1;
  }
`;
