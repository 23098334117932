import { Typography } from '../../components/ui/typography';
import { Question } from '../../components/domain/question';
import { VideoPlayer } from '../../components/domain/video-player';

const log = (l: any) => {
  console.log(l);
  return true;
};

const title = (
  <>
    ORGANO DE OPERACIÓN ADMINISTRATIVA DESCONCENTRADA EN SINALOA <br /> JEFATURA
    DE SERVICIOS DE SALUD EN EL TRABAJO, PRESTACIONES ECONOMICAS Y SOCIALES.
    <br />
    DEPARTAMENTO DE PRESTACIONES SOCIALES
  </>
);
const SectionText = ({ children }: any) => (
  <Typography
    style={{ fontSize: '24px', maxWidth: '1000px' }}
    align='center'
    variant='paragraph'
  >
    {children}
  </Typography>
);

export function createSections(
  cuestionario: any,
  preguntas: any,
  videos: any,
  _curso: any
) {
  return [
    {
      title,
      hasNext: true,
      Component: () => (
        <SectionText>
          Área de Prescripción Social en Salud <br /> Programa de Atención
          Social a la Salud (PASS)
        </SectionText>
      ),
    },
    ...preguntas.data.map((pregunta: any, index: any) => ({
      id: pregunta.id,
      title,
      question: pregunta.texto,
      type: 'question',
      hasBack: true,
      Component: Question,
      ...(index === preguntas.data.length && {
        nextOverrideText: 'Pasar a seccion de videos',
      }),
    })),
    {
      title,
      Component: () => (
        <SectionText>
          A countinuacion le mostraremos 3 videos para que posteriormente
          conteste de nuevo el cuestionario
        </SectionText>
      ),
      hasNext: true,
    },

    ...videos.data.map(
      (video: any) =>
        log(video) && {
          id: video.filename_disk,
          title,
          type: 'video',
          Component: VideoPlayer,
          hasNext: true,
        }
    ),
    {
      title,
      hasNext: true,
      Component: () => (
        <SectionText>
          Ahora le haremos por segunda vez as preguntas sobre los videos que
          acaba de ver
        </SectionText>
      ),
    },
    ...preguntas.data.map((pregunta: any, index: any) => ({
      id: pregunta.id,
      title,
      question: pregunta.texto,
      type: 'question',
      hasBack: true,
      isSecondTime: true,
      Component: Question,
    })),
    {
      title,
      Component: () => (
        <SectionText>
          ¡Has finalizado la prueba! Solo da click en “Enviar respuestas” y todo
          estara listo
        </SectionText>
      ),
      hasNext: true,
      question:
        'Si su enfermedad se atiende correctamente y a tiempo puede prevenir',
      nextOverrideText: 'Enviar respuestas',
    },
  ];
}
