import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

const apiUrl = 'https://api.passimsssinaloa.com';

function translate(word: any) {
  return word === 'yes' ? 'Si' : 'no' ? 'No' : 'No se';
}

const query = `
{
    items {
      
      asignaciones(limit: 10000, sort: ["creation_date"]) {
        creation_date
        cuestionario {
          nombre
        }
        paciente {
          nombre
          apellido_paterno
          apellido_materno
          unidad {
            nombre
            codigo
          }
          telefono
          empresa {
            name
          }
        }
        respuestas
      }
    }
  }
`;

export const Reports = () => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    (async () => {
      const {
        data: {
          data: {
            items: { asignaciones },
          },
        },
      } = await axios.post(
        `${apiUrl}/graphql`,
        { query },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      const headers = [
        'Fecha',
        'Nombre de la empresa',
        'Numero de unidad',
        'Nombre de unidad',
        'Cuestionario',
        'Nombre',
        'Apellido materno',
        'Apellido paterno',
        'Telefono',
        'Tiene respuestas',
        'Respuestas',
      ];
      const csvData = [
        headers,
        ...asignaciones.map((asignacion: any) => [
          asignacion.creation_date,
          asignacion?.paciente?.empresa?.name,
          asignacion?.paciente?.unidad?.codigo,
          asignacion?.paciente?.unidad?.nombre,
          asignacion?.cuestionario?.nombre,
          asignacion?.paciente?.nombre,
          asignacion?.paciente?.apellido_materno,
          asignacion?.paciente?.apellido_paterno,
          asignacion?.paciente?.telefono,
          asignacion?.respuestas ? 'Si' : 'No',
          ...(asignacion?.respuestas
            ? asignacion?.respuestas.map(
              ({ question, answer }: any) =>
                `${question}: ${translate(answer)}`
            )
            : []),
        ]),
      ];
      console.log(asignaciones);
      console.log(csvData);
      setData(csvData);
    })();
  }, []);
  return (
    <div>
      {data && (
        <CSVLink filename='Reportes.csv' data={data}>
          Descargar reporte
        </CSVLink>
      )}
    </div>
  );
};
