import React from 'react';
import { createContext, ReactNode, useMemo } from 'react';
import { TypographyTypes, ITheme, theme } from '../../../theme';

import { createStyledComponent } from './styled';

type TypographyType = keyof typeof theme.typography;

type TypographyContext = {
  components: { [type in TypographyType]: any };
};

export const typographyContext = createContext<TypographyContext>({
  components: {
    h1: <h1>h1</h1>,
    h2: <h2>h1</h2>,
    smallTitle: <h3>h1</h3>,
    paragraph: <p>p</p>,
    small: <small>small</small>,
  },
});

export function TypographyProvider({
  children,
  theme,
}: {
  children: ReactNode;
  theme: ITheme;
}) {
  const components = useMemo(() => {
    const typographyComponents = { ...theme.typography } as any;
    Object.keys(typographyComponents).forEach((key) => {
      typographyComponents[key] = createStyledComponent(
        key as TypographyType,
        theme.typography[key as TypographyTypes]
      );
    });
    return typographyComponents;
  }, [theme]);

  return (
    <typographyContext.Provider value={{ components }}>
      {children}
    </typographyContext.Provider>
  );
}
