import { Switch, Route, Redirect } from 'react-router-dom';
import { Access } from './routes/access';
import { Questionary } from './routes/questionary';
import { Code } from './routes/code';
import { Reports } from './routes/reports';
export function MainRouter() {
  return (
    <Switch>
      <Route path='/access' component={Access} />
      <Route path='/questionary' component={Questionary} />
      <Route path='/code/:id' component={Code} />
      <Route path='/reports' component={Reports} />
      <Redirect to='/access' />
    </Switch>
  );
}
