import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { ThemeColorType } from '../../../theme';
import { StyledButton } from './styled';

export type ButtonProps = {
  color?: ThemeColorType;
  children: ReactNode;
  onClick?: () => void;
  fullWidth?: boolean;
  align?: 'center' | 'right' | 'left';
  size?: 'normal' | 'large';
  style?: CSSProperties;
};

export const Button = ({
  color,
  children,
  fullWidth,
  onClick,
  align,
  size,
  style,
}: ButtonProps) => {
  return (
    <StyledButton
      onClick={onClick}
      size={size || 'normal'}
      style={{
        ...(fullWidth && { width: '100%' }),
        ...(align && { textAlign: align }),
        ...style,
      }}
      color={color || 'primary'}
    >
      {children}
    </StyledButton>
  );
};
